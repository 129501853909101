/* src/Login.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

body, html {
  font-family: 'Inter', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
}

.logo {
  width: 250px;
  max-width: 100%;
  margin-bottom: 20px;
}

.login-box {
  flex: 1;
  max-width: 450px;
  padding: 40px 50px;
  background: white;
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.login-box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
}

.input-field {
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  border: 1px solid #dddfe2;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.input-field:focus {
  border-color: #2589CA;
  outline: none;
}

.login-button {
  background: linear-gradient(90deg, #2589CA 0%, #1a6ba8 100%);
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin: 20px 0 10px;
  font-weight: 600;
  font-size: 1rem;
  transition: background 0.3s, opacity 0.3s;
}

.login-button:hover {
  background: linear-gradient(90deg, #1a6ba8 0%, #2589CA 100%);
}

.login-button:disabled {
  background: #c0c0c0;
  cursor: not-allowed;
  opacity: 0.6;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
  background-color: transparent;
}

/* Responsive design */
@media (max-width: 768px) {
  .login-box {
    padding: 30px 20px;
  }

  .logo {
    width: 200px;
  }

  .input-field {
    padding: 12px;
  }

  .login-button {
    padding: 12px;
    font-size: 0.9rem;
  }
}