/* Main container styling */
.reports-container {
  font-family: Arial, sans-serif;
  height: 100vh;
  /* Full viewport height */
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #F4F4F4;
  padding: 0;
  /* Remove padding at the top and bottom */
  box-sizing: border-box;
  overflow: auto;
}

/* Header styling */
.report-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distributes content to left and right */
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 101;
  position: sticky;
  top: 0;
  max-height: 8vh;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.logo {
  width: 80px;
  height: auto;
  margin-top: 15px;
}

.user-avatar-reports {
  height: 60px;
  width: 80px;
  position: relative;
  margin-left: -1vw;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  color: #222;
  text-align: center;
  margin-top: 2vh;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  margin-left: 26vw;
}

.title::after {
  content: "";
  width: 16vw;
  height: 5px;
  background-color: #2589ca;
  display: block;
  margin: 5px auto 0;
  border-radius: 10px;
}

/* Create Report Button */
.create-report-container {
  text-align: left;
  /* Align button to the left */
  margin: 20px 0;
  padding-right: 10vh;
  padding-left: 5vh;
}

.create-report-button {
  background-color: #2589ca;
  color: white;
  font-size: 1rem;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.create-report-button:hover {
  background-color: #0056b3;
}

/* Reports List Styling */
.reports-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* Responsive grid */
  gap: 20px;
  margin-top: 10px;
  min-height: 50vh;
  /* Ensures space is always reserved for the list */
  padding-right: 5vh;
  padding-left: 5vh;
}

.report-description {
  white-space: normal; /* Allows text to wrap onto the next line */
  overflow-wrap: break-word; /* Breaks long words if needed */
  max-width: 100%; /* Ensures it stays within the card width */
  display: block;
}


.report-card {
  background: #ffffff;
  border-radius: 10px;
  min-height: 40vh;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  /* Add this line to change the cursor */
}

.report-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.report-name {
  font-size: 1.3rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
}

.report-date {
  font-size: 0.9rem;
  color: #666;
  margin-top: 30px;
}

/* Delete Button Styling */
.delete-report-button {
  background-color: #007bff;
  /* Blue color */
  color: white;
  /* White text */
  font-size: 1rem;
  /* Font size */
  padding: 8px 12px;
  /* Padding for button */
  border: none;
  /* Remove default border */
  border-radius: 6px;
  /* Rounded border */
  cursor: pointer;
  /* Pointer cursor */
  transition: background-color 0.3s, transform 0.2s;
  /* Add smooth hover effect */
  margin-top: 50px;
  /* Add spacing above button */
}

.delete-report-button:hover {
  background-color: #0056b3;
  /* Darker blue on hover */
  transform: scale(1.05);
  /* Slightly enlarge on hover */
}

.error-message {
  color: red;
  font-size: 1rem;
  margin: 20px 0;
  text-align: center;
  /* Center error message */
}