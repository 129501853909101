.main-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  background-color: #ffffff; /* Ensure the main container has a white background */
}

.data-view-section {
  min-width: 50%;
  max-width: 87.5%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
  z-index: 2;
}

.user-avatar {
  height: 60px;
  width: 80px;
  position: relative;
  margin-left: 70vh;
  transition: margin-left 0.09s ease; /* Add a transition for smooth adjustment */
}


.logo-container {
  display: flex;
  justify-content: left;
  /* Center the logo horizontally */
  margin-bottom: 10px;
  /* Add some space between the logo and the button */
}

.sarovar-logo {
  width: 90px;
  /* Adjust the width as needed */
  height: 70px;
  /* Maintain the aspect ratio */
}




.export-button-container {
  display: flex;
  justify-content: flex-end; /* Move the button to the right */
  margin-bottom: 10px;
}

.export-button {
  display: flex;
  align-items: center; /* Align text and icon vertically */
  background-color: transparent; /* Remove background color */
  border: none; /* Remove the border */
  cursor: pointer;
  padding: 0; /* Remove padding */
}

.download-text {
  font-size: 16px; /* Set the desired font size */
  color: #2589CA; /* Match the previous button color */
  margin-right: 8px; /* Add some space between the text and the icon */
  font-weight: 600; /* Inter-SemiBold */
}

.download-icon {
  width: 34px; /* Set the desired width for the icon */
  height: auto; /* Maintain the aspect ratio */
}

.tabs-container {
  display: flex;
  justify-content: flex-start;
  background-color: #FFFFFF;
  padding: 10px 0;
  border-bottom: 2px solid #ddd;
  position: sticky;
  top: 68px; /* Sticky position below the header */
  z-index: 100;
  padding-top: 6vh;
}

.tabs-container button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #2589CA;
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s;
}

.tabs-container button.active-tab {
  border-bottom: 2px solid #2589CA;
}

.tab-content {
  padding: 10px;
}

.visualization-tab-content {
  display: grid; /* Change to grid layout */
  grid-template-columns: 1fr; /* Initially, display one image per row */
  gap: 20px; /* Space between images */
  overflow-y: auto;
  max-height: 80vh;
}


.visualization-tab-content img {
  width: 100%; /* Ensure the image takes up the full grid cell */
  transition: transform 0.2s ease, width 0.2s ease; /* Smooth transition for zooming */
  cursor: grab; /* Indicate that the image is draggable */
  margin-bottom: 3vh;
}

.table-tab-content .export-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

/* Custom scrollbar styling for the visualization tab */
.visualization-tab-content::-webkit-scrollbar {
  width: 8px; /* Set width for the scrollbar */
  height: 8px;
}

.visualization-tab-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background for the scrollbar track */
}

.visualization-tab-content::-webkit-scrollbar-thumb {
  background-color: #EB8223; /* Orange scrollbars */
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.visualization-tab-content::-webkit-scrollbar-thumb:hover {
  background-color: #d06c1f; /* Darker orange on hover */
}

.data-table-container {
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: auto; /* Ensure auto height */
  max-height: calc(100vh - 200px); /* Adjust based on the viewport */
  z-index: 2;
}



/* Loader styles */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #2589CA; /* Your desired color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}

.loader-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #777; /* Your desired text color */
}



.data-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
  margin-bottom: 4vh; /* Add this to create the gap between the table and scrollbar */
  max-height: 100%;  /* Ensures the table stays within the container */
  overflow: auto;    /* Triggers the scroll */
}

.table-header {
  padding: 18px;
  background-color: #2589CA;
  color: #fff;
  font-weight: 600; /* Inter-SemiBold */
  font-family: 'Inter', sans-serif;
  position: sticky;
  top: 0;
  z-index: 3; /* Added */
  border-bottom: 2px solid #ddd;
  white-space: nowrap;
  font-size: 12px;
}

.table-cell {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  font-family: 'Inter', sans-serif; /* Inter font for table values */
  font-weight: 400; /* Regular weight */
  font-size: 12px;
}

/* For alternating row colors */
.data-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9; /* Light gray for odd rows */
}

.data-table tbody tr:nth-child(even) {
  background-color: #ffffff; /* White for even rows */
}

.selected-row {
  background-color: #F5AC72;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55vh;
  color: #777;
  font-size: 16px;
  margin-top: 20px; /* Add a margin to space it from the top */
  padding: 20px; /* Add padding if needed for better appearance */
  text-align: center; /* Ensure text is centered */
}


.error-message {
  padding: 10px;
  color: red;
  background-color: #ffe6e6;
  border-radius: 4px;
  margin-top: 10px;
}

.chat-section {
  min-width: 12.5%;
  max-width: 50%;
  background-color: #F2F2F2;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ddd;
  position: relative;
  overflow: hidden;
}

.chat-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0; /* Adjust the padding as needed */
}

.sarovar-logo-middle {
  width: 80px; /* Adjust the size as necessary */
  margin-bottom: 15px; /* Space between the logo and the text */
}

.intro-text {
  font-size: 14px; /* Adjust the text size as needed */
  font-weight: 600;
  color: #333; /* Adjust the color if needed */
  margin: 0;
  text-align: center;
}


.new-chat-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #f4f4f4; /* Match the chat section background */
  border-bottom: 1px solid #ddd;
  min-height: 10vh;
}

.new-chat-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

/* Chat history toggle button */
.toggle-chat-history-container {
  display: flex;
  align-items: right;
  justify-content: flex-start;
  padding: 10px;
  background-color: #f4f4f4;
  cursor: pointer;
  border-bottom: none; /* Ensure no bottom border is present */
}

.chat-history-icon {
  width: 15px;
  height: 10px;
  border: none; /* Ensure no border is present */
  text-decoration: none; /* Remove any potential underline */
  outline: none; /* Remove any outline */
  margin-left: 5vh; /* Set initial margin */
  transition: margin-left 0.1s ease; /* Smooth transition for margin changes */  
}

/* Chat history container */
.chat-history-container {
  padding: 20px;
  overflow-y: auto;
  max-height: 50vh;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.chat-history-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #007bff;
}

.chat-history-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.chat-history-text {
  margin: 0;
  font-size: 14px;
}

.share-button {
  background-color: #f4f4f4;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.no-chat-history {
  font-size: 14px;
  color: #777;
}

.new-chat-icon {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  margin-right: 8px;
}


.resize-handle {
  width: 5px;
  cursor: ew-resize;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background-color: transparent;
}

.chat-content {
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
  direction: rtl;
}

.chat-content > * {
  direction: ltr; /* Ensures the content inside the chat box remains left-to-right */
}

.chat-message {
  margin-bottom: 20px;
}

.user-message {
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.bot-message {
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px;
}

.selected-chat .bot-message {
  background-color: #a1cce9; /* Light blue color for highlighting */
}


.chat-label {
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  margin-bottom: 5px;
  font-size: 14px;
}

.chat-label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.chat-label-avatar {
  width: 31px; /* Adjust the size as needed */
  height: 31px; /* Adjust the size as needed */
  margin-right: 8px;
  border-radius: 50%; /* Make the image circular */
  margin-bottom: 5px;
}



.start-conversation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #777;
  font-size: 16px;
  text-align: center;
  padding: 0 20px;
}

.input-area {
  padding: 10px 15px;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
}

.input-textarea {
  resize: none;
  width: 100%;
  height: 80px;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin-left: -10px;
}

.landing-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #ffffff;
  /* Assuming a white background for the header */
}

.header-line {
  position: absolute;
  bottom: 0;
  /* Aligns the line to the bottom of the header */
  left: 0;
  width: 100%;
  height: 3px;
  /* Adjust height as needed */
  background-color: #EB8223;
  /* Orange color similar to the scrollbar */
}


.send-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
}

.send-button-image {
  width: 5ch;
  /* Adjust size as needed */
  height: 24px;
  /* Adjust size as needed */
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.send-button-image:hover {
  transform: scale(1.1);
  /* Slightly increase size on hover */
}

.send-button-image:active {
  transform: scale(0.9);
  /* Slightly decrease size on click */
}

.column-view-buttons {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin: 20px 30px; /* Adjust margin for spacing */
}

.column-view-buttons button {
  background-color: #FFA500; /* Orange background */
  border: none;
  color: white;
  padding: 8px 16px; /* Reduced padding for a smaller button */
  margin-left: 8px; /* Reduced space between buttons */
  margin-top: -25px;
  border-radius: 4px; /* Slightly smaller border radius */
  cursor: pointer;
  font-size: 14px; /* Smaller font size */
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}


.column-view-buttons button.active {
  background-color: #1E90FF; /* Blue color for active state */
}

.column-view-buttons button:focus {
  outline: none;
}



/* Custom scrollbar styling */
.data-table-container::-webkit-scrollbar,
.chat-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.data-table-container::-webkit-scrollbar-track,
.chat-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.data-table-container::-webkit-scrollbar-thumb,
.chat-content::-webkit-scrollbar-thumb {
  background-color: #EB8223;
  /* Orange scrollbars */
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.data-table-container::-webkit-scrollbar-thumb:hover,
.chat-content::-webkit-scrollbar-thumb:hover {
  background-color: #d06c1f;
}

.create-report-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 400px;
}

.create-report-modal h2 {
  margin-bottom: 10px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.submit-button, .cancel-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #ccc;
}

.create-report-button-container {
  display: flex;
  justify-content: flex-start; /* Align button to the left */
  align-items: center;
  padding: 10px 20px; /* Add padding for spacing */
  background-color: #f9f9f9; /* Light background for contrast */
  border-bottom: 1px solid #ddd; /* Bottom border for separation */
}

.create-report-button {
  background-color: #007bff; /* Blue background */
  color: white;
  border: none;
  padding: 10px 20px; /* Spacing around the button */
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-report-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slight scaling effect */
}

.create-report-button:active {
  background-color: #004085; /* Even darker blue on click */
  transform: scale(0.95); /* Slight shrink on click */
}

/* Updated CSS for Save Report Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.save-report-modal {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2589CA;
}

.input-field,
.textarea-field {
  width: calc(100%); /* Ensure both fields have the same width */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box; /* Ensures padding does not increase width */
}

.textarea-field {
  height: 80px;
  resize: none;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.save-button.disabled {
  background-color: gray;
  cursor: not-allowed;
}

.cancel-button-report {
  background-color: #ccc;
  color: #000;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.cancel-button-report:hover {
  background-color: #aaa;
}

/* Save Report Button in Chat Section */
.save-report-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #2589CA;
}

.save-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.dashboard-button {
  background-color: #007bff; /* Blue */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.close-button {
  background-color: #dc3545; /* Red */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.close-button:hover {
  background-color: #b02a37; /* Darker red */
}

.dashboard-button:hover {
  background-color: #0056b3; /* Darker blue */
}
