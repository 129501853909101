/* Main container */
.view-report-container {
  font-family: 'Inter', sans-serif;
  padding: 20px;
  background-color: #F4F4F4;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.view-report-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distributes content to left and right */
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 101;
  position: sticky;
  top: 0;
  max-height: 8vh;
  min-width: 96vw;
}

.header-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.left-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.view-report-user-avatar {
  height: 60px;
  width: 80px;
  position: relative;
  margin-left: 42vw;
}


.go-back-button {
  background-color: #2589ca;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 87vw;
  
}

.go-back-button:hover {
  background-color: #0056b3;
}


.logo {
  width: 80px;
  height: auto;
}

.view-report-tabs-container {
  display: flex;
  justify-content: flex-start;
  background-color: #F4F4F4;
  padding: 10px 0;
  border-bottom: 2px solid #ddd;
  position: sticky;
  top: 68px; /* Sticky position below the header */
  z-index: 100;
  padding-top: 6vh;
}

.view-report-tabs-container button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #2589CA;
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s;
}

.tabs-container button.active-tab {
  border-bottom: 2px solid #2589CA;
}

.tab-content {
  padding: 20px 0;
}

.table-header {
  background-color: #2589CA;
  color: white;
  font-weight: 600;
  padding: 18px;
  text-align: left;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 3;
  border-bottom: 2px solid #ddd;
}

.table-cell {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.even-row {
  background-color: #ffffff;
}

.odd-row {
  background-color: #f9f9f9;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px; /* Adjust spacing */
}

.report-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: -1vh;
  margin-bottom: -1vh;
  margin-left: -1vw; /* Remove default margin */
}

.export-button-container {
  display: flex;
  align-items: center;
}

.export-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  margin-right: 2vw;
}

.download-text {
  font-size: 16px;
  color: #2589CA;
  margin-right: 8px;
  font-weight: 600;
}

.data-table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.data-table-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.data-table-container::-webkit-scrollbar-thumb {
  background-color: #EB8223;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.data-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #d06c1f;
}


.visualization-tab-content {
  display: grid;
  gap: 20px;
  overflow-y: auto;
  max-height: 80vh;
}

.chart-container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.column-view-buttons-report-view {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  padding-right: 0;
}

.column-view-buttons-report-view button {
  background-color: #FFA500;
  border: none;
  color: white;
  padding: 8px 16px;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.column-view-buttons-report-view button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.column-view-buttons-report-view button.active {
  background-color: #1E90FF;
}

.column-view-buttons-report-view button:focus {
  outline: none;
}

.go-back-container {
  margin-top: 20px;
}



.error-message {
  color: red;
  background-color: #ffe6e6;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}
